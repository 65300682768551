<template>
  <div class="mx-auto px-4 my-6 space-y-6 sm:px-6 lg:px-0 lg:col-span-3 h-[200vh]">
    <div>
      <button class="btn btn-highlight" type="button" @click="toggleSlideOver">
        Open Slide-Over
      </button>
      <SlideOver :isOpen="isSlideOverOpen" @toggleSlideOver="toggleSlideOver">
        <span>Your content goes here</span>
      </SlideOver>
    </div>
    <button class="btn btn-highlight" type="button" @click="toggleModal">Open modal</button>
    <Modal :isOpen="isModalOpen" @toggleModal="toggleModal">
      <span>Content of modal goes here..</span>
    </Modal>
  </div>
</template>

<script>
import { ref } from "vue";
import Modal from "@/components/UI/Popups/Modal/AppModal";
import SlideOver from "@/components/UI/Popups/SlideOver/AppSlideOver";

export default {
  name: "ComponentsHub",
  components: {
    SlideOver,
    Modal
  },
  setup() {
    const isSlideOverOpen = ref(false);
    const toggleSlideOver = () => {
      isSlideOverOpen.value = !isSlideOverOpen.value;
    };

    const isModalOpen = ref(false);
    const toggleModal = () => {
      isModalOpen.value = !isModalOpen.value;
    };

    return {
      isSlideOverOpen,
      toggleSlideOver,
      isModalOpen,
      toggleModal
    };
  }
};
</script>
